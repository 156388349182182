.carousel-item-wrapper {
  height: 650px;
  overflow: hidden;
  object-fit: cover;
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.carousel-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 150px;
  width: 100%;
}

.carousel-header img {
  max-height: 100px;
  max-width: 200px;
  overflow: hidden;
  object-fit: contain;
}

.year {
  text-wrap: nowrap;
  font-size: 50px;
  font: 50px "Roboto", sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

.name {
  text-wrap: nowrap;
  font-size: 30px;
  font: 30px "Roboto", sans-serif;
  text-shadow: 2px 2px 4px rgba(255, 0, 0, 0.6);
}

.carousel-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  width: 100%;
}

.carousel-content img {
  max-height: 400px;
  max-width: 400px;
  overflow: hidden;
  object-fit: cover;
  border-radius: 2%;
}

.carousel-content p {
  max-width: 750px;
  text-align: left;
  margin-left: 30px;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-mask-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 25, 0.3)
  );
  mask-image: linear-gradient(
    rgba(255, 255, 25, 0.3),
    rgba(255, 255, 255, 0.3)
  );
}

@media screen and (max-width: 960px) {
  .carousel-item-wrapper {
    padding: 0;
  }
  .carousel-header {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    width: 100%;
  }

  .carousel-header-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .carousel-header img {
    max-width: 150px;
    margin-bottom: 5px;
  }

  .year {
    font-size: 30px;
    margin-right: 15px;
  }

  .name {
    font-size: 30px;
    margin-left: 15px;
    /* text-wrap: wrap; */
  }

  .carousel-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    width: 100%;
  }

  .carousel-content img {
    max-width: 90vw;
    max-height: 90vw;
  }

  .carousel-content p {
    margin-top: 10px;
  }
}
