.hero-container {
  /* background: url("/public/images/flatironsHome.png"); */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  justify-content: center;
}

.hero-container h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.num2036 {
  color: #ff0000;
}

.hero-container p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.hero-btns {
  margin-top: 32px;
  z-index: 10000;
}

.hero-btns .btn {
  margin: 6px;
}

.hero-btns img {
  filter: brightness(0) saturate(100%);
  transform: translateY(3px);
  margin-left: 4px;
  max-height: 20px;
}

.hero-btns .btn:hover img {
  filter: brightness(0) saturate(100%) invert(1);
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container h1 {
    font-size: 70px;
    margin-top: -150px;
  }

  .hero-container p {
    font-size: 30px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container p {
    font-size: 25px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

.inset {
  position: absolute;
  inset: 0;
}

.full {
  height: 100vh;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gradient {
  background: linear-gradient(rgba(0, 16, 46, 0.5) 50%, black);
}

.gradientTop {
  background: linear-gradient(rgba(27, 25, 25, 1), rgba(39, 57, 67, 0.01));
  height: 20vh;
}

.mask {
  background: rgba(0, 0, 0, 0.4);
  height: 100vh;
}
