.footer-container {
  background-color: #242424;
  padding: 1rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.social-media-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-media-wrapper img {
  width: 35px;
  height: 35px;
  transition: 0.3s ease all;
}

.social-media-wrapper img:hover {
  transform: scale(1.1);
}

.social-icon-link {
  color: #fff;
  font-size: 24px;
  padding: 30px;
}

.contact-us-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 24px;
}

.contact-us {
  color: #fff;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact-us h1 {
  margin-bottom: 5px;
}

.contact-us h6 {
  font-size: 20px;
  letter-spacing: -3px;
  margin-bottom: 8px;
}

.blackKnightsEmail {
  margin-bottom: 20px;
}

.blackKnightsEmail a {
  color: #fff;
  text-decoration: none;
}

.contact-us iframe {
  margin-top: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 100px;
}

.form input,
textarea {
  min-width: 550px;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: none;
  resize: none;
  font-size: 15px;
}

.form Button {
  margin-top: 10px;
  width: 550px;
}

.footer-input-big {
  height: 125px;
}

@media screen and (max-width: 960px) {
  .btn {
    width: 100%;
  }

  .social-icon-link {
    padding: 20px;
  }

  .contact-us-wrapper {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }

  .contact-us {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .contact-us iframe {
    width: 250px;
  }

  .form {
    margin-left: 0px;
  }

  .form input,
  textarea {
    min-width: 300px;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    border: none;
    resize: none;
    font-size: 15px;
  }

  .form Button {
    width: 300px;
  }
}
