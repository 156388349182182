.carousel-wrapper {
  width: 100vw;
}

.carousel-wrapper img {
  height: 650px;
  overflow: hidden;
  object-fit: cover;
}

.carousel-scroll-btn {
  /* display: none; */
  opacity: 0;
  position: absolute;
  top: 85%;
  left: 80%;
  /* transform: translateY(-50%); */
  background-color: rgba(0, 0, 0, 0.5);
  /* padding: 10px; */
  border-radius: 10px;
  /* cursor: pointer; */
  height: 60px;
  width: 60px;
  border-color: rgba(255, 255, 255, 0.7);
  z-index: 100;
  border-style: solid;
  cursor: default;
  transition: 0.3s ease all;
}

.carousel-scroll-btn img {
  width: 40px;
  height: 40px;
  margin-top: 5px;
}

@media screen and (max-width: 960px) {
  .carousel-scroll-btn {
    opacity: 1;
    cursor: pointer;
  }
}
