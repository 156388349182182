.fade-in-section-left {
  opacity: 0;
  transform: translateX(-20vw);
  visibility: hidden;
  transition: opacity 0.2s ease-out, transform 0.6s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section-right {
  opacity: 0;
  transform: translateX(20vw);
  visibility: hidden;
  transition: opacity 0.2s ease-out, transform 0.6s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section-left.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
.fade-in-section-right.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
